<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
      scrollable
    >
      <template #modal-header>
        <div class="modal-title">{{ product.productName }}</div>
        <b-button variant="transfer" @click="transfer">โอน</b-button>
      </template>
      <div class="d-block">
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          sticky-header
          empty-text="No matching records found"
          class="table-main mb-0"
          :no-border-collapse="false"
        >
          <template v-slot:cell(price)="data">
            <div>{{ data.item.price | numeral("0,0.00") }}</div>
          </template>
          <template v-slot:cell(current_stock)="data">
            <div>{{ data.item.current_stock | numeral("0,0") }}</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
      <template #modal-footer="{ hide }">
        <div class="text-center w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      isBusy: false,
      showModal: false,
      fields: [
        {
          key: "branch_name",
          label: "สาขา",
          thStyle: { width: "65%" },
          tdClass: "text-left",
          sortable: true,
        },
        {
          key: "current_stock",
          label: "จำนวนคงเหลือ",
          thStyle: { width: "35%" },
          sortable: true,
        },
      ],
      items: [],
      product: {
        id: 0,
        productName: "",
        currentStock: 0,
      },
      productRaw: {},
    };
  },

  methods: {
    async show(item) {
      this.productRaw = item;
      this.product.id = item.product_id;
      this.product.productName = item.name;

      await this.getOtherBranchInventory(this.product.id);

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async getOtherBranchInventory(productId) {
      await this.$store.dispatch("setMainLoading", true);
      this.isBusy = true;
      await this.axios
        .get(`${this.$baseUrl}/Inventory/GetBranchs/${productId}`)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.items = data.detail;
          }
        });
      this.isBusy = false;
    },
    transfer() {
      this.$emit("handleTransfer", this.productRaw);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
::v-deep .b-table-sticky-header > .table.b-table > thead > tr > th {
  font-size: 16px;
  background-color: var(--secondary-color);
  color: var(--font-color);
  position: sticky !important;
}
::v-deep .b-table-sticky-header {
  max-height: calc(65vh - 46px);
}
.btn-transfer {
  text-decoration: underline;
  padding: 0px 0px;
  color: white;
}
</style>
